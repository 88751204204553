import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../logo/logo.png';
import Notice from './Notice';
import './header.css';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const Header = () => {
  const { width } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const isMobile = width <= 992;
  const dropdownRef = useRef(null);
  const collapseNav = () => {
    setOpen(false);
  };
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const toggleDropdown = (dropdownId) => {
    setDropdownOpen(dropdownId === dropdownOpen ? null : dropdownId);
  };

  const handleMouseEnter = (dropdownId) => {
    if (window.innerWidth < 960) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(true);
    }
    if (!isMobile) {
      setDropdownOpen(dropdownId);
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const handleMouseLeave = () => {
    // Check if the window width is less than 960 pixels
    if (window.innerWidth < 960) {
      // For mobile resolutions, immediately deactivate the dropdown
      setDropdown(false);
      setDropdownOpen(null); // Ensure dropdown is fully closed
    } else {
      // For non-mobile resolutions, use a timeout to deactivate the dropdown
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear any existing timeout
      }

      // Set a new timeout to deactivate the dropdown after 300 milliseconds
      const newTimeoutId = setTimeout(() => {
        setDropdownOpen(null); // Deactivate the dropdown
      }, 300);

      // Save the new timeout ID to state
      setTimeoutId(newTimeoutId);
    }
  };

  // Scrolling to a section using an Id
  const scrollToSection = (elementsID) => {
    const element = document.getElementById(elementsID);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <header className="main-header">
        <div className="top-bar d-none d-md-block">
          <div className="container px-md-0">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <i className="fas fa-clock-o"></i> <strong>Mon To Fri:</strong>{' '}
                08:00 - 16:30
              </div>
              <div className="col-md-6 col-sm-12">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <Link to="cdn-cgi/l/email-protection.html#0a63646c654a6e6f676524696567">
                      <i className="far fa-envelope"></i>{' '}
                      info@examscouncil.org.ls
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <i className="fas fa-phone"></i> (+266) 22312880 / 52300100
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="stricky slideIn animated" id="strickyMenu">
          <div className="container-md px-md-0">
            <nav id="nav" className="navbar navbar-expand-lg" role="navigation">
              <div className="container-md px-md-0">
                <a href="/" className="navbar-brand">
                  <img src={logo} alt="Logo" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  aria-controls="mainNav"
                  aria-expanded={open}
                  aria-label="Toggle navigation"
                  onClick={() => setOpen(!open)}
                >
                  <span className="fas fa-bars"></span>
                </button>

                <div
                  id="mainNav"
                  className={`navbar-collapse collapse${open ? ' show' : ''}`}
                >
                  <ul className="nav navbar-nav ml-auto navbar-style3">
                    <li className="nav-item">
                      <Link className="nav-link" to="/" onClick={collapseNav}>
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/about-us"
                        onClick={collapseNav}
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/services"
                        onClick={collapseNav}
                      >
                        Services
                      </Link>
                    </li>
                    <li
                      className="nav-item dropdown"
                      onMouseEnter={() => handleMouseEnter('examinations')}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Link
                        to="#"
                        className="nav-link"
                        onClick={
                          isMobile
                            ? () => toggleDropdown('examinations')
                            : undefined
                        }
                      >
                        Examinations
                        <i className="bx bx-chevron-down dropdown_icon"></i>
                      </Link>
                      {dropdownOpen === 'examinations' && (
                        <div className="dropdown-menu">
                          <Link
                            className="dropdown-item"
                            to="/examinations-fees"
                            onClick={collapseNav}
                          >
                            Fee
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/my-result"
                            onClick={collapseNav}
                          >
                            Individual Results
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/results"
                            onClick={collapseNav}
                          >
                            Results and Statistics
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/syllabus"
                            onClick={collapseNav}
                          >
                            Syllabus
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/programmes"
                            onClick={collapseNav}
                          >
                            Programmes
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/examiners-report"
                            onClick={collapseNav}
                          >
                            Examiner report
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/past-question-paper"
                            onClick={collapseNav}
                          >
                            Past Question Paper
                          </Link>
                        </div>
                      )}
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/opportunities"
                        onClick={collapseNav}
                      >
                        Opportunities
                      </Link>
                    </li>
                    <li
                      className="nav-item dropdown"
                      onMouseEnter={() => handleMouseEnter('publications')}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Link
                        to="#"
                        className="nav-link"
                        onClick={
                          isMobile
                            ? () => toggleDropdown('publications')
                            : undefined
                        }
                      >
                        Publications
                        <i className="bx bx-chevron-down dropdown_icon"></i>
                      </Link>
                      {dropdownOpen === 'publications' && (
                        <div className="dropdown-menu">
                          <Link
                            className="dropdown-item"
                            to="/media-release"
                            onClick={collapseNav}
                          >
                            Media release
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/newsletters"
                            onClick={collapseNav}
                          >
                            News letter
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/documents"
                            onClick={collapseNav}
                          >
                            Documents
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/gallery"
                            onClick={collapseNav}
                          >
                            Gallery
                          </Link>
                        </div>
                      )}
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/contact-us"
                        onClick={collapseNav}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <Notice></Notice>
    </>
  );
};

export default Header;
