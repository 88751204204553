import React from 'react';
// import certificate from "../images/education.JPG";
import Picture_1 from '../images/service-clock.jpg';

import './services.css';

const Services = () => {
  return (
    <>
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Services</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Services</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}

      <div className="conduct_layout">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="full">{/* Optional content */}</div>
            </div>
          </div>

          <div className="row top-img">
            <div className="col-md-5 col-sm-12 col-xsm-12 image_contain">
              <div className="conduct-img">
                <img
                  src={Picture_1}
                  alt="Picture_1"
                  className="w-75 img-thumbnail"
                />
                <img
                  src={Picture_1}
                  width={1300}
                  alt="Picture_1"
                  className="w-75 img-thumbnail"
                />
              </div>
            </div>
            <div className="col-md-7 mt-2">
              <div className="full">
                <div className="container px-md-0">
                  <div className="sec-title style-two mb-tt">
                    <h2>
                      Conducting school-level assessments on behalf of the
                      Ministry of Education and Training (MoET).
                    </h2>

                    <span className="decor">
                      <span className="inner" />
                    </span>
                  </div>
                </div>
                <p align="justify">
                  The Examinations Council of Lesotho (ECoL) is a non-profit
                  organization which is responsible for conducting school-level
                  assessments on behalf of the Ministry of Education and
                  Training (MoET). conducts End-of-Level Assessments at
                  specified points in the education system, for example, Grade4
                  and Grade7. ECoL further carries out public examinations at
                  the end of Junior Secondary, Form C (Junior Certificate
                  Examination) and at the end of Senior Secondary, Form E (O’
                  Level/LGCSE). The O’ Level curriculum and examinations are
                  accredited by Cambridge International Assessment...
                </p>
                <p>
                  <a className="btn btn-1" href="/about-us">
                    Read More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="eservice">
        <div className="container px-md-0">
          <div className="sec-title text-center">
            <h2>Services</h2>
            <p>Payment method : M-PESA or EFT</p>
            <span className="decor">
              <span className="inner" />
            </span>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xsm-12 ftco-animate">
              <div className="block-7">
                <div className="text-left p-1">
                  <span className="except d-block">
                    Evaluation of Foreign Results
                  </span>
                  <ul className="pricing-text mb-2">
                    <li>
                      <span className="fas fa-check mr-3"></span> Evaluation
                      Fee:{' '}
                      <span className="price">
                        <span className="number"> M 525.00</span>
                      </span>
                    </li>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b> Requirements</b>
                    </li>{' '}
                    <p className="service_p">
                      ~ Copy of statement of results/certificate.
                    </p>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Results</b>: within 5 working days.
                    </li>
                  </ul>

                  <a href="https://ecol.org.ls/services" className="btn">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xsm-12 ftco-animate">
              <div className="block-7">
                <div className="text-left p-1">
                  <span className="except d-block">
                    GRADE 7/LGCSE Results Printing
                  </span>
                  <ul className="pricing-text mb-2">
                    <li>
                      <span className="fas fa-check mr-3"></span> LGCSE -
                      Printing Fee:{' '}
                      <span className="price">
                        <span className="number">M 165.00</span>
                      </span>
                      <br />
                      <span className="fas fa-check mr-3"></span> Grade 7 -
                      Printing Fee:{' '}
                      <span className="price">
                        <span className="number">M 165.00</span>
                      </span>
                    </li>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Requirements</b>
                    </li>{' '}
                    <p className="service_p">~ Letter From School.</p>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Results</b>: within a day.
                    </li>
                  </ul>
                  <a href="https://ecol.org.ls/services" className="btn">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xsm-12 ftco-animate">
              <div className="block-7">
                <div className="text-left p-1">
                  <span className="except d-block">
                    Results/Certificate Verification
                  </span>
                  <ul className="pricing-text mb-2">
                    <li>
                      <span className="fas fa-check mr-3"></span> Local
                      Verification:{' '}
                      <span className="price">
                        <span className="number text text-end">M 200.00</span>
                      </span>
                      <br />
                      <span className="fas fa-check mr-3"></span> Foreign
                      Verification:{' '}
                      <span className="price">
                        <span className="number align-right">M 405.00</span>
                      </span>
                      <br />
                      <span className="fas fa-check mr-3"></span> Education
                      Institutions Verification:{' '}
                      <span className="price">
                        <span className="number text-xxl-end">M 200.00</span>
                      </span>
                    </li>
                    <li>
                      <span className=" mr-3"></span>
                      <b>Note:</b>
                    </li>{' '}
                    <p className="service_p">
                      Local Verification(Employers/Companies).
                    </p>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Results</b>: within 5 working days.
                    </li>
                  </ul>
                  <a href="https://ecol.org.ls/services" className="btn">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xsm-12 ftco-animate">
              <div className="block-7">
                <div className="text-left p-1">
                  <span className="except d-block">
                    Certificate Replacement
                  </span>
                  <ul className="pricing-text mb-2">
                    <li>
                      <span className="fas fa-check mr-3"></span> PSLE Fee:{' '}
                      <span className="price">
                        <span className="number">M 345.00</span>
                      </span>
                      <br />
                      <span className="fas fa-check mr-3"></span> JC Fee:{' '}
                      <span className="price">
                        <span className="number">M 645.00</span>
                      </span>
                      <br />
                      <span className="fas fa-check mr-2"></span> LGCSE/COSC
                      Fee:{' '}
                      <span className="price">
                        <span className="number">M 1245.00</span>
                      </span>
                    </li>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Requirements</b>
                    </li>{' '}
                    <p className="service_p">
                      ~ Letter from school & avidafit from Police.
                    </p>{' '}
                    <p className="service_p">
                      ~ Passport size photo(coloured).
                    </p>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Results</b>: within 5 Working days.
                    </li>
                  </ul>
                  <a href="https://ecol.org.ls/services" className="btn">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xsm-12 ftco-animate">
              <div className="block-7">
                <div className="text-left p-1">
                  <span className="except d-block">MIGRATION CERTIFICATE</span>
                  <ul className="pricing-text mb-2">
                    <li>
                      <span className="fas fa-check mr-3"></span> Migration
                      Certificate Fee:{' '}
                      <span className="price">
                        <span className="number">M 1245.00</span>
                      </span>
                    </li>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Requirements</b>
                    </li>
                    <p className="service_p">
                      {' '}
                      ~ Original LGSCE/COSC Certificate.
                    </p>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Results</b>: within a day.
                    </li>
                  </ul>
                  <a href="https://ecol.org.ls/services" className="btn">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xsm-12 ftco-animate">
              <div className="block-7">
                <div className="text-left p-1">
                  <span className="except d-block">MIGRATION CERTIFICATE</span>
                  <ul className="pricing-text mb-2">
                    <li>
                      <span className="fas fa-check mr-3"></span> English
                      Proficiency Fee:{' '}
                      <span className="price">
                        <span className="number">M 105.00</span>
                      </span>
                    </li>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Requirements</b>
                    </li>
                    <p className="service_p">
                      {' '}
                      ~ Original LGSCE/COSC Certificate.
                    </p>
                    <li>
                      <span className="fas fa-circle mr-3"></span>{' '}
                      <b>Results</b>: within 2 Working days.
                    </li>
                  </ul>
                  <a href="https://ecol.org.ls/services" className="btn">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Code here about us */}
        </div>
      </section>
    </>
  );
};

export default Services;
