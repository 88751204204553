import React from 'react';
import Slider from '../componets/slider/Slider';
import Whoweare from '../componets/whoweare/Whoweare';
// import CountDown from '../componets/countdown/CountDown';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useQuery, gql } from '@apollo/client';
import Aboutinfo from '../componets/partials/aboutinf/Aboutinfo';
import MediaRelease_inner from './publications/MediaRelease_inner';

export const Home = () => {
  // const deadline = 'Oct 08, 2022 21:00:00';

  const GET_LOCATIONS = gql`
    query {
      mediaReleases {
        data {
          attributes {
            title
            description
            media_release {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error } = useQuery(GET_LOCATIONS);

  if (loading) {
    return <div>{process.env.REACT_APP_API_URL}</div>;
  }
  if (error) {
    return <div>{process.env.REACT_APP_API_URL}</div>;
  }

  return (
    <>
      <Slider />
      <Whoweare></Whoweare>
      <Aboutinfo />
      <MediaRelease_inner />
      {/* <CountDown deadline={deadline} /> */}
    </>
  );
};
